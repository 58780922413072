import React, { useState } from "react";
import {
  GetStartedSection,
  EnterProviderSection,
  EnterOTPSection,
} from "helper/GetStartedPageHelper";
import { verifyOTP, sendOTP, signInWithEmailPassword } from "api/auth";

import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
export default function GetStartedPage({
  animatedLogo,
  animatedbackground,
  themeData,
}) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [sectionActivate, setSectionActivate] = useState(1);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100vh",
        // backgroundColor: "#060715",
        background: themeData.colors.get_started_background,
        overflow: "hidden",
      }}
      className="flex items-center justify-center"
    >
      {animatedbackground && (
        <Player
          autoplay
          loop={false}
          className="absolute inset-0 z-0"
          src={animatedbackground}
          keepLastFrame={true}
        ></Player>
      )}
      {animatedLogo && (
        <Player
          autoplay
          loop={false}
          className="absolute inset-0 z-0"
          src={animatedLogo}
          keepLastFrame={true}
        ></Player>
      )}

      {sectionActivate === 1 ? (
        <GetStartedSection
          onClickButton={() => {
            setSectionActivate(2);
          }}
        />
      ) : sectionActivate === 2 ? (
        <EnterProviderSection
          onClickButton={() => {
            signInWithEmailPassword(email, password).then(() => {
              navigate("/homepage");
            });
          }}
          // onClickButton={() => {
          //   sendOTP(email);
          //   setSectionActivate(3);
          // }}
          setEmail={setEmail}
          setPassword={setPassword}
        />
      ) : //sectionActivate === 3 ? (
      //   <EnterOTPSection
      //     changeEmailClick={() => {
      //       setSectionActivate(2);
      //     }}
      //     submitOTPClick={() => {
      //       if (otp === "" || email === "") {
      //         alert("Please enter OTP or Email");
      //       } else {
      //         verifyOTP(email, otp).then(() => {
      //           navigate("/homepage");
      //         });
      //       }
      //     }}
      //     setOTP={setOTP}
      //     email={email}
      //   />
      // ) :
      null}
      <h1
        style={{
          position: "absolute",
          bottom: "5px",
          right: "20px",
        }}
        className="hover:text-white text-transparent font-thin text-[12px]"
      >
        v0.0.5
      </h1>
    </div>
  );
}

// ----------------------------
//  Copyright (C) 2024 Everlign LLC
//  All Rights Reserved.

//  This file is part of Everlign's Generative AI Platform.

//  Proprietary and confidential. Unauthorized copying of this file,
//  via any medium, is strictly prohibited.

//  File Name: GetStartedPage.js
//  Author: Everlign Development Team
// ----------------------------
