import { useEffect, useState } from "react";
import { getOrgsAPI } from "api/storage";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// export function SelectOrgSection(props) {
//   const [orgs, setOrgs] = useState([]);
//   const [openDropdown, setOpenDropdown] = useState(false);
//   const dispatch = useDispatch();

//   const { setSectionActivate } = props;

//   const buttonStyle = {
//     border: "1px solid rgba(255, 255, 255, 0.05)",
//     background:
//       "linear-gradient(180deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.25) 100%)",
//   };
//   useEffect(() => {
//     getOrgsAPI().then((response) => {
//       setOrgs(response.data);
//     });
//   }, []);
//   return (
//     <div className="relative z-10 flex flex-col justify-end pb-[2%] items-center h-full">
//       <div className="flex flex-col items-center">
//         <h1 className="text-white font-medium text-3xl">AI-Driven Insights</h1>
//         <h1 className="text-white mt-1 font-thin text-center">
//           Harness the power of AI!
//           <br />
//           Powered by ive AI Platform
//         </h1>

//         <button
//           onClick={() => setOpenDropdown(!openDropdown)}
//           style={buttonStyle}
//           className="px-6 py-2 rounded-lg mt-10 cursor-pointer z-10"
//         >
//           <h1 className="text-[#FFF]">Select Organisation</h1>
//         </button>
//         {openDropdown ? (
//           <div className="bg-[#2A283A] h-40 overflow-y-auto text-center opacity-[50%] rounded-md z-20">
//             {orgs.map((org) => (
//               <div
//                 onClick={() => {
//                   // dispatch(setUserOrg(org));
//                   // setThemeData(org).then(() => {
//                   //   setSectionActivate();
//                   // });
//                 }}
//                 key={org}
//                 className="text-[#FFF] my-4 mx-5 cursor-pointer"
//               >
//                 {org}
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="h-40"></div>
//         )}
//       </div>
//       <h1
//         style={{
//           position: "absolute",
//           bottom: "20px",
//         }}
//         className="hover:text-white text-transparent font-thin text-[12px]"
//       >
//         v0.0.2
//       </h1>
//     </div>
//   );
// }
export function GetStartedSection(props) {
  const { onClickButton } = props;

  const userData = useSelector((state) => state.userData);

  const buttonStyle = {
    border: "1px solid rgba(255, 255, 255, 0.05)",
    background: userData.org.colors.get_started_button,
  };

  const get_started_heading_1_style = {
    color: userData.org.colors.get_started_heading_style?.text_color,
    fontWeight: userData.org.colors.get_started_heading_style?.text_weight,
  };
  return (
    <div className="relative z-10 flex flex-col justify-end pb-[10%] items-center h-full">
      <div className="flex flex-col items-center">
        <h1
          style={get_started_heading_1_style}
          className="text-primary-light font-medium text-xl 2xl:text-3xl"
        >
          {userData.org.colors.get_started_heading}
        </h1>
        <h1 className="text-get_started_subheading_color text-sm 2xl:text-base mt-3 font-thin text-center">
          {userData.org.colors.get_started_sub_heading[0]}
          <br />
          {userData.org.colors.get_started_sub_heading[1]}
          <br />
        </h1>
        <button
          onClick={onClickButton}
          style={buttonStyle}
          className="px-6 py-2 rounded-lg mt-10 cursor-pointer"
        >
          <h1 className="text-[#FFF] text-sm 2xl:text-base">Get Started</h1>
        </button>
      </div>
      <h2
        className="text-get_started_subheading_color text-xs"
        style={{
          position: "absolute",
          bottom: "5px",
          // right: "20px",
        }}
      >
        Powered by Everlign's Generative AI Platform
      </h2>
    </div>
  );
}

export function EnterProviderSection(props) {
  const userData = useSelector((state) => state.userData);

  const { onClickButton, setEmail, setPassword } = props;

  const buttonStyle = {
    border: "1px solid rgba(255, 255, 255, 0.05)",
    background: userData.org.colors.get_started_button,
  };

  const inputStyle = {
    borderRadius: "8px",
    border: "1px solid rgba(255, 255, 255, 0.10)",
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 88.89%)",
    boxShadow:
      "0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
    backDropFilter: "blur(10px)",
  };
  return (
    <div className="relative z-10 flex flex-col justify-end pb-[7%] items-center h-full">
      <div className="flex flex-col items-center">
        <h1 className="text-get_started_subheading_color font-medium text-xl 2xl:text-3xl">
          Let's get started
        </h1>
        {/* <h1 className="text-white mt-3 font-thin text-center">
          Registered email
        </h1> */}
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="Email"
          style={inputStyle}
          className="px-6 py-2 rounded-lg mt-5 2xl:mt-10 cursor-pointer focus:outline-none text-primary-light placeholder-get_started_placeholder_color"
        />
        <input
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Password"
          type="password"
          style={inputStyle}
          className="px-6 py-2 rounded-lg mt-4 cursor-pointer focus:outline-none text-primary-light placeholder-get_started_placeholder_color"
        />
        <button
          onClick={onClickButton}
          style={buttonStyle}
          className="px-6 py-2 rounded-lg mt-10 cursor-pointer"
        >
          <h1 className="text-[#FFF] text-sm 2xl:text-base">Login</h1>
        </button>
      </div>
    </div>
  );
}

export function EnterOTPSection(props) {
  const { changeEmailClick, submitOTPClick, setOTP, email } = props;
  const userData = useSelector((state) => state.userData);

  const buttonStyle = {
    border: "1px solid rgba(255, 255, 255, 0.05)",
    background: userData.org.colors.get_started_button,
  };

  const inputStyle = {
    borderRadius: "8px",
    border: "1px solid rgba(255, 255, 255, 0.10)",
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 88.89%)",
    boxShadow:
      "0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
    backDropFilter: "blur(10px)",
  };
  const get_started_heading_1_style = {
    color: userData.org.colors.get_started_heading_style?.text_color,
    fontWeight: userData.org.colors.get_started_heading_style?.text_weight,
  };
  return (
    <div className="relative z-10 flex flex-col justify-end pb-[7%] items-center h-full">
      <div className="flex flex-col items-center">
        <h1
          style={get_started_heading_1_style}
          className="text-primary-light font-medium text-3xl"
        >
          Check your email
        </h1>
        <h1 className="text-text-primary mt-3 font-thin text-center">
          Enter the verifications code we sent to
          <br />
          {email}
        </h1>
        <input
          onChange={(e) => {
            setOTP(e.target.value);
          }}
          placeholder="6 digit security code"
          style={inputStyle}
          className="px-6 py-2 rounded-lg mt-10 cursor-pointer focus:outline-none "
        />
        <div className="flex flex-row">
          <button
            onClick={changeEmailClick}
            style={buttonStyle}
            className="px-6 py-2 rounded-lg mt-10 cursor-pointer"
          >
            <h1 className="text-[#FFF]">Resend</h1>
          </button>
          <button
            onClick={submitOTPClick}
            style={buttonStyle}
            className="px-6 py-2 rounded-lg mt-10 ml-4 cursor-pointer"
          >
            <h1 className="text-[#FFF]">Submit</h1>
          </button>
        </div>
        {/* <h1
          onClick={changeEmailClick}
          className="text-text-primary text-sm mt-4 cursor-pointer"
        >
          go back
        </h1> */}
      </div>
    </div>
  );
}

export async function setThemeColors(userThemeData) {
  document.title = userThemeData.colors.html_title;

  const favicon = document.getElementById("favicon");
  if (favicon) {
    favicon.href = userThemeData.org_favicon;
  } else {
    const link = document.createElement("link");
    link.id = "favicon";
    link.rel = "icon";
    link.href = userThemeData.org_favicon;
    document.head.appendChild(link);
  }

  document.documentElement.style.setProperty(
    "--primary-light",
    userThemeData.colors.primary ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--primary-light"
      )
  );
  document.documentElement.style.setProperty(
    "--secondry-light",
    userThemeData.colors.secondary ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--secondry-light"
      )
  );
  document.documentElement.style.setProperty(
    "--background-dark",
    userThemeData.colors.background_dark ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--background-dark"
      )
  );
  document.documentElement.style.setProperty(
    "--foreground-dark",
    userThemeData.colors.foreground_dark ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--foreground-dark"
      )
  );
  document.documentElement.style.setProperty(
    "--divider-dark",
    userThemeData.colors.divider_dark ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--divider-dark"
      )
  );
  document.documentElement.style.setProperty(
    "--text-dark",
    userThemeData.colors.text_dark ||
      getComputedStyle(document.documentElement).getPropertyValue("--text-dark")
  );
  document.documentElement.style.setProperty(
    "--text-primary",
    userThemeData.colors.text_primary ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--text-primary"
      )
  );
  document.documentElement.style.setProperty(
    "--text-secondry",
    userThemeData.colors.text_secondary ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--text-secondry"
      )
  );

  document.documentElement.style.setProperty(
    "--get_started_subheading_color",
    userThemeData.colors.get_started_subheading_color ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--get_started_subheading_color"
      )
  );

  document.documentElement.style.setProperty(
    "--get_started_placeholder_color",
    userThemeData.colors.get_started_placeholder_color ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--get_started_placeholder_color"
      )
  );

  document.documentElement.style.setProperty(
    "--logo_color",
    userThemeData.colors.logo_color ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--logo_color"
      )
  );

  const styleSheet = document.styleSheets[0];
  const fontFaceRule = `
    @font-face {
      font-family: "Franie";
      src: url("${userThemeData.org_fonts["semibold.ttf"]}") format("truetype");
      font-weight: 600;
    }
  `;

  const fontFaceRule2 = `
    @font-face {
      font-family: "Franie";
      src: url("${userThemeData.org_fonts["thin.ttf"]}") format("truetype");
      font-weight: 400;
    }
  `;

  // Add the font-face rule to the first stylesheet
  if (styleSheet.insertRule) {
    styleSheet.insertRule(fontFaceRule, styleSheet.cssRules.length);
    styleSheet.insertRule(fontFaceRule2, styleSheet.cssRules.length);
  }

  return userThemeData;
}

// ----------------------------
//  Copyright (C) 2024 Everlign LLC
//  All Rights Reserved.

//  This file is part of Everlign's Generative AI Platform.

//  Proprietary and confidential. Unauthorized copying of this file,
//  via any medium, is strictly prohibited.

//  File Name: GetStartedPageHelper.js
//  Author: Everlign Development Team
// ----------------------------
