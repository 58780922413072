import React, { useState, useEffect } from "react";
import AllPages from "./AllPages";
import { IoClose } from "react-icons/io5";
import { getFileAPI } from "api/storage";

const ModalAllPages = ({
  filePreview,
  showFilePreview,
  setShowFilePreview,
  specificPages,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fileData, setFileData] = useState(null);

  useEffect(() => {
    setFileData(null);
    getFileAPI(filePreview["source"], filePreview["page_number"]).then(
      (response) => {
        setFileData(response.data);
        setIsLoading(false);
      }
    );
  }, [filePreview]);
  return (
    <div
      id="staticModal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden="true"
      className={`${
        showFilePreview ? "" : "hidden"
      } bg-gray-100 dark:bg-foreground-dark border-2 border-gray-100 dark:border-background-dark  flex flex-col fixed  rounded-lg left-[2.5%] right-[2.5%] z-50  w-[95%] h-[90%]  `}
    >
      <div className="h-[10%] rounded-t-lg text-end bg-white dark:bg-foreground-dark flex flex-row justify-center items-center px-8">
        <h4 className="text-color-text text-lg">{filePreview.name}</h4>

        <button
          onClick={() => {
            setShowFilePreview(!showFilePreview);
            setIsLoading(true);
          }}
          type="button"
          className="text-color-text bg-transparent hover:bg-gray-200 dark:hover:bg-background-dark hover:text-color-text rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
          data-modal-hide="staticModal"
        >
          <IoClose size={20} className="dark:fill-white" />
        </button>
      </div>
      <div className="h-[90%] p-4 overflow-x-hidden overflow-y-auto">
        {isLoading === false || fileData != null ? (
          <>
            {filePreview["source"] ? (
              <AllPages
                pdf={fileData.url}
                specificPages={[filePreview["page_number"]]}
              />
            ) : (
              <AllPages pdf={filePreview} specificPages={[1]} />
            )}
          </>
        ) : (
          <h1 className="dark:text-white">Loading</h1>
        )}
      </div>
    </div>
  );
};

export default ModalAllPages;
