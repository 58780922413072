import { useNavigate } from "react-router-dom";
import { sendLogToServer } from "./logging";
import axios from "axios";
import apiEndpoints from "./apiConfig";
import { handleApiError } from "./apiConfig";

export const LogoutButton = ({ children }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    sendLogToServer("Logout");
    localStorage.removeItem("access_token");
    navigate("/");
  };

  return <div onClick={handleLogout}>{children}</div>;
};

export const signInWithEmailPassword = async (email, password) => {
  sendLogToServer("Login with Email Password Started");
  try {
    const response = await axios.post(apiEndpoints.AUTH.LOGIN, {
      email: email,
      password: password,
    });

    localStorage.setItem("access_token", response.data.access_token);
  } catch (error) {
    alert("Invalid Email or Password");
  }
};

export const fetchSession = async () => {
  sendLogToServer("Fetching Session");
  try {
    const session = await axios.get(apiEndpoints.AUTH.SESSION_CHECK, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    if (session.user === null) {
      return null;
    } else {
      return session;
    }
  } catch (error) {
    sendLogToServer(`Error fetching session:${error}`);
    return null;
  }
};

export const sendOTP = async (email) => {
  sendLogToServer("sendOTP");
  try {
    const response = await axios.post(apiEndpoints.AUTH.SEND_OTP, {
      email: email,
    });
    localStorage.setItem("session_id", response.data.session_id);
  } catch (error) {
    sendLogToServer(`Error createConversationNewChat: ${error}`);
    handleApiError(error);
  }
};

export const verifyOTP = async (email, otp) => {
  sendLogToServer("Login with Email Password Started");
  try {
    const response = await axios.post(apiEndpoints.AUTH.VERIFY_OTP, {
      email: email,
      otp: otp,
      session_id: localStorage.getItem("session_id"),
    });

    localStorage.setItem("access_token", response.data.access_token);
  } catch (error) {
    alert("Invalid Email or Password");
  }
};
